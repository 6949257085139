import {
  ImageFit,
  Stack,
  Image,
  Dropdown,
  DefaultButton,
  ChoiceGroup,
  DatePicker,
  defaultDatePickerStrings,
  addDays,
  Panel,
  Dialog,
  DialogFooter,
  PrimaryButton,
  DialogType,
  DayOfWeek,
  Shimmer,
  ShimmerElementType,
  ShimmerElementsGroup,
  Label,
  Toggle,
} from "@fluentui/react";
import React, { Component } from "react";
import {
  addDefaultSrc,
  MyDayAPIHelper,
} from "../infrastructure/MyDayAPIHelper";
import {
  ITheme,
  mergeStyleSets,
  getTheme,
  getFocusStyle,
} from "@fluentui/react/lib/Styling";
import { UsersClient } from "../infrastructure/api/UsersClient";
import { HomeClient, HomeQueryParams } from "../infrastructure/api/HomeClient";
import {
  EventsQueryParams,
  EventsClient,
} from "../infrastructure/api/EventsClient";
import { Icon } from "@fluentui/react/lib/Icon";
import { NoTeam } from "./NoTeam";
import { TeamMyDayChart } from "./Charts/TeamMyDayChart";
import { DaySelectChart } from "./Charts/DaySelectChart";
import { EventsTable } from "./Charts/EventsTable";
import {
  NotWorkingReasonClient,
  NotWorkingReasonQueryParams,
} from "../infrastructure/api/NotWorkingReasonClient";
import {
  SiteQueryParams,
  SitesClient,
} from "../infrastructure/api/SitesClient";
import {
  AssetsClient,
  AssetsQueryParams,
} from "../infrastructure/api/AssetsClient";
import { WorkDaysClient } from "../infrastructure/api/WorkDaysClient";
import { TeamTable } from "./Charts/TeamTable";
import { PlanTable } from "./Charts/PlanTable";
import { WeekSelector } from "./Charts/WeekSelector";
import { ActivityFeeds } from "./Charts/ActivityFeeds";
import { BookedAssetsLight } from "./Charts/BookedAssetsLight";
import { getContext } from "@microsoft/teams-js";
import * as microsoftTeams from "@microsoft/teams-js";
import { ThrottlingUtils } from "@azure/msal-common";
import { BookAssetModal } from "./Modals/BookAssetModal";
import { SiteSelector } from "../components/Selectors/SiteSelector";
import { FloorSelector } from "../components/Selectors/FloorSelector";
import { AssetTypeSelector } from "../components/Selectors/AssetTypeSelector";
import { NeighborhoodSelector } from "../components/Selectors/NeighborhoodSelector";
import { EventsList } from "./Charts/EventsList";
import { EventsListLight } from "./Charts/EventsListLight";
import moment from "moment";
import Moment from "react-moment";

export class Assets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assets: [],
      assetsLoading: false,
      userId: null,
      user: {},
      selectedDatesForUpdate: [],
      loading: false,
      total: 0,
      skip: 0,
      take: 50,
      search: "",
      sort: "created",
      direction: "desc",
      selectedTeam: null,
      initComplete: false,
      notWorkingReasons: [],
      sites: [],
      currentDate: new Date(new Date().toDateString()),
      isOpen: false,
      currentWeekStartDate: this.getMonday(new Date(new Date().toDateString())),
      refreshWidgets: 1,
      isDatePickerOpen: false,
      isCheckInOpen: false,
      selectedMyDay: {
        date: new Date(new Date().toDateString()),
        status: "planned",
        parts: [
          {
            state: "workFromOffice",
          },
        ],
      },
      showEvents: false,
      modalVisible: false,
      events: [],
      selectedSite: null,
      selectedFloor: null,
      selectedAssetType: null,
      selectedNeighborhood: null,
      teamOnly: false,
      assetRefresh: false,
      initialized: false,
    };
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  classNames = mergeStyleSets({
    container: {
      overflow: "auto",
      maxHeight: 450,
    },
    itemHeader: {
      backgroundColor: "#4052AB",
      color: "white",
      borderRadius: "5px 5px 0 0",
    },
    itemCell: [
      getFocusStyle(getTheme(), { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: "border-box",
        borderBottom: `1px solid ${getTheme().semanticColors.bodyDivider}`,
        display: "flex",
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
        selectors: {
          "&:hover": { background: getTheme().palette.neutralLight },

          boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.1)",
        },
      },
    ],
    itemImage: {
      flexShrink: 0,
    },
    itemContent: {
      marginLeft: 10,
      marginRight: 10,
      overflow: "hidden",
      flexGrow: 1,
      display: "flex",
      justifyContent: "space-between",
    },
    itemName: [
      getTheme().fonts.xLarge,
      {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    ],
    panelItemName: [
      getTheme().fonts.large,
      {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    ],
    itemIndex: {
      fontSize: getTheme().fonts.small.fontSize,
      color: getTheme().palette.neutralTertiary,
      marginBottom: 10,
    },
    chevron: {
      alignSelf: "center",
      marginLeft: 10,
      color: getTheme().palette.neutralTertiary,
      fontSize: getTheme().fonts.large.fontSize,
      flexShrink: 0,
    },
    contentClass: {
      backgroundColor: getTheme().palette.neutralLight,
      color: getTheme().palette.neutralDark,
      lineHeight: "50px",
      padding: "0 20px",
      background:
        "linear-gradient(141deg, rgba(252,194,80,0.5) 0%, rgba(240,110,108,0.5) 50%, rgba(12,133,144,0.5) 100%)",
    },
    dropdown: { width: 300, borderRadius: "10px" },
    exampleRoot: {
      marginTop: "20px",
    },
    nameText: {
      fontWeight: "bold",
    },
    stackRoot: {
      width: "100%",
      display: "flex",
    },
    firstStack: { width: "33%" },
    secondStack: { width: "33%" },
    thirdStack: { width: "33%" },
  });

  modalStyles = mergeStyleSets({
    root: {
      width: "600px",
      height: "600px",
    },
    container: {},
  });

  componentDidMount() {
    this.update();
  }

  async update() {
    console.log(getContext());

    await this.populateData();
  }

  render() {
    var stackTokens = {
      childrenGap: 20,
      padding: 20,
    };

    const { events, userId } = this.state;

    return (
      <>
        {this.state.selectedTeam != null && (
          <>
            <div className={this.classNames.contentClass}>
              <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                <Stack
                  horizontal
                  tokens={{ childrenGap: 20 }}
                  horizontalAlign="space-between"
                >
                  <Stack
                    horizontal
                    tokens={{ childrenGap: 20 }}
                    verticalAlign="center"
                  >
                    {this.state.teams.length == 1 && (
                      <>
                        <b style={{ lineHeight: "30px" }}>
                          {this.state.selectedTeam.name}
                        </b>
                        <Icon
                          iconName="Group"
                          style={{
                            position: "relative",
                            top: "1px",
                            lineHeight: "30px",
                            marginLeft: "10px",
                          }}
                        />{" "}
                      </>
                    )}
                    {this.state.teams.length > 1 && (
                      <>
                        <Dropdown
                          placeholder="Select a team"
                          selectedKey={
                            this.state.selectedTeam
                              ? this.state.selectedTeam.id
                              : null
                          }
                          options={this.state.teams.map((item) => {
                            return {
                              key: item.id,
                              text: item.name,
                            };
                          })}
                          onChanged={(item) =>
                            this.setState(
                              {
                                selectedTeam: {
                                  id: item.key,
                                  name: item.text,
                                },
                              },
                              this.update
                            )
                          }
                          className={this.classNames.dropdown}
                        />

                        <DefaultButton
                          style={{ display: "none" }}
                          text="Next team"
                          onClick={() => {
                            var count = this.state.teams.length;
                            var currentIndex = this.state.teams.findIndex(
                              (item) => item.id == this.state.selectedTeam.id
                            );
                            var targetIndex = (currentIndex + 1) % count;
                            this.setState(
                              {
                                selectedTeam: {
                                  id: this.state.teams[targetIndex].id,
                                  name: this.state.teams[targetIndex].name,
                                },
                              },
                              this.populateData
                            );
                          }}
                          disabled={this.state.loading}
                        />
                      </>
                    )}

                    {/* <DatePicker
                      id="currentDatePicker"
                      minDate={new Date()}
                      firstDayOfWeek={DayOfWeek.Monday}
                      value={this.state.currentDate}
                      onSelectDate={(date) =>
                        this.setState(
                          {
                            currentDate: date,
                            currentWeekStartDate: this.getMonday(date),
                          },
                          this.populateData
                        )
                      }
                      placeholder="Select a date..."
                      ariaLabel="Select a date"
                      // DatePicker uses English strings by default. For localized apps, you must override this prop.
                      strings={defaultDatePickerStrings}
                    /> */}
                  </Stack>

                  <Stack horizontal tokens={{ childrenGap: 20 }}>
                    <DefaultButton
                      style={{
                        borderRadius: "50%",
                        minWidth: "0",
                        height: "50px",
                        width: "50px",
                      }}
                      onClick={() => this.backwardOneWeek()}
                      disabled={
                        this.state.loading ||
                        this.state.currentWeekStartDate.getTime() <
                          new Date().getTime()
                      }
                    >
                      <Icon iconName="DoubleChevronLeft" />
                    </DefaultButton>

                    {this.state.userSummary &&
                      this.state.userSummary.map((day) => {
                        return (
                          <div style={{ width: "50px" }}>
                            <DaySelectChart
                              key={new Date(day.date).getTime()}
                              isSelected={
                                this.state.currentDate &&
                                new Date(day.date).getTime() ==
                                  this.state.currentDate.getTime()
                              }
                              date={new Date(day.date)}
                              pieData={day.pieChart}
                              onClick={() => {
                                this.setState(
                                  {
                                    currentDate: new Date(day.date),
                                    loading: true,
                                  },
                                  this.updateMyDay
                                );
                              }}
                              teamEvent={day?.teamEvent}
                            />
                          </div>
                        );
                      })}

                    <DefaultButton
                      style={{
                        borderRadius: "50%",
                        minWidth: "0",
                        height: "50px",
                        width: "50px",
                      }}
                      onClick={() => this.forwardOneWeek()}
                      disabled={this.state.loading}
                    >
                      <Icon iconName="DoubleChevronRight" />
                    </DefaultButton>
                  </Stack>
                </Stack>
              </div>
            </div>

            <div className={this.classNames.stackRoot}>
              <div className={this.classNames.firstStack}>
                <Stack
                  horizontal
                  tokens={stackTokens}
                  horizontalAlign="space-around"
                >
                  <Stack.Item
                    style={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Label>Filters</Label>
                        <div
                          style={{
                            display: "flex",
                            alignSelf: "center",
                          }}
                        ></div>
                      </div>
                      <div
                        className="card"
                        style={{
                          padding: "10px 20px",
                          height: "1000px",
                          overflow: "scroll",
                        }}
                      >
                        <Label>Date</Label>
                        <DatePicker
                          firstDayOfWeek={DayOfWeek.Monday}
                          id="currentDatePicker2"
                          minDate={new Date()}
                          value={this.state.currentDate}
                          onSelectDate={(date) =>
                            this.setState(
                              {
                                currentDate: date,
                                currentWeekStartDate: this.getMonday(date),
                                isDatePickerOpen: false,
                              },
                              this.populateData
                            )
                          }
                          placeholder="Select a date..."
                          ariaLabel="Select a date"
                          // DatePicker uses English strings by default. For localized apps, you must override this prop.
                          strings={defaultDatePickerStrings}
                        />

                        <SiteSelector
                          onSelect={(item) => {
                            this.setState({ selectedSite: item }, () =>
                              this.getAssets()
                            );
                          }}
                          siteId={this.state.user?.site?.id}
                        />
                        <FloorSelector
                          siteId={this.state.selectedSite?.id}
                          onSelect={(item) => {
                            this.setState({ selectedFloor: item }, () =>
                              this.getAssets()
                            );
                          }}
                        />
                        <NeighborhoodSelector />
                        <Toggle label="Team Only" />

                        <AssetTypeSelector
                          onSelect={(value) => {
                            this.setState({ selectedAssetType: value }, () =>
                              this.getAssets()
                            );
                          }}
                        />

                        <div style={{ paddingTop: "20px" }}></div>
                        {events && events.length > 0 && (
                          <>
                            <Label>Events ({events.length})</Label>
                            <EventsListLight
                              events={events}
                              userId={userId}
                              refreshEvents={() => {
                                this.populateEvents();
                              }}
                            />
                          </>
                        )}
                        <div style={{ paddingTop: "20px" }}></div>
                        <BookedAssetsLight
                          selectedDate={this.state.currentDate}
                          userId={userId}
                          refresh={this.state.assetRefresh}
                        />
                      </div>
                    </div>
                  </Stack.Item>
                </Stack>
              </div>
              <div className={this.classNames.secondStack}>
                <Stack
                  horizontal
                  tokens={stackTokens}
                  horizontalAlign="space-around"
                >
                  <Stack.Item
                    style={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Label>Assets</Label>
                        <div
                          style={{
                            display: "flex",
                            alignSelf: "center",
                          }}
                        ></div>
                      </div>
                      <div
                        className="card"
                        style={{
                          padding: "10px 20px",
                          height: "1000px",
                          overflow: "scroll",
                        }}
                      >
                        {this.state.assetsLoading && (
                          <div>
                            <Shimmer style={{ padding: "5px 20px" }} />
                            <Shimmer
                              style={{ padding: "5px 20px" }}
                              width="75%"
                            />
                            <Shimmer
                              style={{ padding: "5px 20px" }}
                              width="50%"
                            />
                          </div>
                        )}
                        {!this.state.assetsLoading &&
                          this.state.assets.length > 0 &&
                          this.state.assets.map((item) => (
                            <div
                              className={this.classNames.itemCell + " hover"}
                              style={{
                                backgroundColor: "white",
                                borderRadius: "15px",
                                marginBottom: "10px",
                              }}
                              onClick={() => {
                                this.setState({ selectedData: item }, () =>
                                  this.setState({
                                    modalVisible: true,
                                  })
                                );
                              }}
                            >
                              <div className={this.classNames.itemContent}>
                                <div>
                                  <div className={this.classNames.itemName}>
                                    {item.name}
                                  </div>
                                  <div className={this.classNames.itemIndex}>
                                    {item.floor?.name}
                                  </div>
                                </div>
                                {item.checkIns && item.checkIns.length > 0 && (
                                  <div>
                                    <div className={this.classNames.itemName}>
                                      {item.checkIns[0]?.user?.name}{" "}
                                      {item.checkIns[0]?.user?.surname}
                                    </div>
                                    <div className={this.classNames.itemIndex}>
                                      <Moment utc local format="HH:mm">
                                        {item.checkIns[0].checkInDate}
                                      </Moment>{" "}
                                      -
                                      <Moment utc local format="HH:mm">
                                        {item.checkIns[0].checkOutDue}
                                      </Moment>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </Stack.Item>
                </Stack>
              </div>
              {/* <div className={this.classNames.thirdStack}>
                <Stack
                  horizontal
                  tokens={stackTokens}
                  horizontalAlign="space-around"
                >
                  <Stack.Item
                    style={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Label>Floor Plan</Label>
                        <div
                          style={{
                            display: "flex",
                            alignSelf: "center",
                          }}
                        ></div>
                      </div>
                      <div
                        className="card"
                        style={{ padding: "10px 20px", height: "450px" }}
                      >
                        {this.state.loading && (
                          <div>
                            <Shimmer style={{ padding: "5px 20px" }} />
                            <Shimmer
                              style={{ padding: "5px 20px" }}
                              width="75%"
                            />
                            <Shimmer
                              style={{ padding: "5px 20px" }}
                              width="50%"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </Stack.Item>
                </Stack>
              </div> */}
            </div>
          </>
        )}

        {this.state.selectedTeam == null && !this.state.loading && <NoTeam />}
        {this.renderBookAssetModal()}
      </>
    );
  }

  renderBookAssetModal() {
    return (
      <BookAssetModal
        modalVisible={this.state.modalVisible}
        selectedData={this.state.selectedData}
        selectedDate={this.state.currentDate}
        userId={this.state.userId}
        onCancel={() => {
          this.setState({ modalVisible: false });
        }}
        onOk={() => {
          this.setState({
            modalVisible: false,
            assetRefresh: !this.state.assetRefresh,
          });
        }}
      />
    );
  }

  forwardOneWeek() {
    var newDate = addDays(this.state.currentDate, 7);
    var newWeekStartDate = this.getMonday(newDate);

    if (
      newWeekStartDate.getTime() < new Date().getTime() &&
      this.state.currentWeekStartDate.getTime() > new Date().getTime()
    ) {
      this.setState(
        {
          currentDate: new Date(new Date().toDateString()),
          currentWeekStartDate: newWeekStartDate,
        },
        this.populateData
      ); //If current week, default to current
    } else {
      this.setState(
        {
          currentDate: newWeekStartDate,
          currentWeekStartDate: newWeekStartDate,
        },
        this.populateData
      ); //If other week, default to Monday
    }
  }

  backwardOneWeek() {
    var newDate = addDays(this.state.currentDate, -7);
    var newWeekStartDate = this.getMonday(newDate);

    if (
      newWeekStartDate.getTime() < new Date().getTime() &&
      this.state.currentWeekStartDate.getTime() > new Date().getTime()
    ) {
      this.setState(
        {
          currentDate: new Date(new Date().toDateString()),
          currentWeekStartDate: newWeekStartDate,
        },
        this.populateData
      ); //If current week, default to current
    } else {
      this.setState(
        {
          currentDate: newWeekStartDate,
          currentWeekStartDate: newWeekStartDate,
        },
        this.populateData
      ); //If other week, default to Monday
    }
  }

  async getAssets() {
    this.setState({ assetsLoading: true });
    var query = new AssetsQueryParams();

    const { selectedSite, selectedFloor, selectedAssetType } = this.state;

    if (selectedSite?.id) query.WithSiteId(selectedSite?.id);
    if (selectedFloor?.id) query.WithFloorId(selectedFloor?.id);

    if (selectedAssetType?.id) query.WithAssetTypeId(selectedAssetType?.id);

    query.WithStart(this.state.currentDate);
    query.WithEnd(moment(this.state.currentDate).add(1, "d").toISOString());
    query.WithStatus("active");
    query.WithCheckForTeamMates(true);

    query.Paginate(0, 2000);

    var response = await AssetsClient.List(query);

    if (response.data) {
      const { data } = response.data;

      this.setState({
        assets: data,
      });
    }
    this.setState({ assetsLoading: false });
  }

  async populateData() {
    this.getAssets();
    if (!this.state.loading) {
      this.setState({ loading: true });
      var currentUser = await UsersClient.Get("current");

      if (currentUser.successful && currentUser.data.site) {
        var siteId = currentUser.data.site.id;
        this.setState({
          siteId: siteId,
          userId: currentUser.data.id,
          user: currentUser.data,
          selectedSite: currentUser.data?.site,
        });

        if (currentUser.data.teams && currentUser.data.teams.length > 0) {
          var teamId = currentUser.data.teams[0].id;

          if (!this.state.selectedTeam) {
            this.setState({
              teams: currentUser.data.teams,
              selectedTeam: {
                id: currentUser.data.teams[0].id,
                name: currentUser.data.teams[0].name,
              },
            });

            this.populateEvents();
          }

          //Get currentUser MyDay
          var userSummary = await HomeClient.DayView(
            new HomeQueryParams()
              .WithSiteId(siteId)
              .WithTeamId(teamId)
              .WithUserId(currentUser.data.id)
              .WithNumberOfDays(7)
              .WithParam("startDate", this.state.currentWeekStartDate)
          );

          console.log(userSummary);

          this.setState({
            userSummary: userSummary.data,
            myDay: this.getMyDayForDate(
              userSummary.data,
              this.state.currentDate
            ),
            initComplete: true,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      } else {
        this.setState({ loading: false });
      }

      var notWorkingReasons = await NotWorkingReasonClient.List(
        new NotWorkingReasonQueryParams()
      );
      this.setState({ notWorkingReasons: notWorkingReasons.data.data });

      var sites = await SitesClient.List(new SiteQueryParams());
      this.setState({
        sites: sites.data.data,
        refreshWidgets: new Date().getTime(),
      });
    }
  }

  async populateEvents() {
    var eventParams = new EventsQueryParams();
    if (this.state.selectedTeam?.id)
      eventParams.WithTeamIds([this.state.selectedTeam.id]);

    var events = await EventsClient.List(eventParams);
    this.setState({
      events: events.data.data,
    });
  }
  updateMyDay() {
    this.setState({
      myDay: this.getMyDayForDate(
        this.state.userSummary,
        this.state.currentDate
      ),
      refreshWidgets: new Date().getTime(),
    });
  }

  getMyDayForDate(data, date) {
    var myDay = {
      date: this.state.currentDate,
      status: "planned",
      parts: [
        {
          state: "workFromOffice",
        },
      ],
    };

    if (data && data.length > 0) {
      for (var i = 0; i < data.length; i++) {
        if (new Date(data[i].date).getTime() == date.getTime()) {
          if (data[i].users.length > 0 && data[i].users[0].myDay) {
            myDay = data[i].users[0].myDay;
          }
        }
      }
    }

    return myDay;
  }
}
